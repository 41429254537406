import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Navigation from './Navigation'
import { Logo } from './Logo'
import { MenuButton } from './MenuButton'
import './Header.scss'

const isClient = typeof window !== 'undefined';

class HeaderTemplate extends Component {
  state = {
    scrolled: false,
    upScroll: false,
    lastScrollPos: 0,
    navOpen: false,
    isHome: false
  };

  componentDidMount() {
    if (isClient) window.addEventListener('scroll', this.handleScroll);
    this.setState({ isHome: this.props && this.props.location && this.props.location.pathname === '/' }) // add is-home class if on home page
  }

  componentWillUnmount() {
    if (isClient) window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    if (event) event.preventDefault();
    const { lastScrollPos } = this.state;
    const currScrollPos = window.scrollY;
    if (currScrollPos > lastScrollPos) {
      // scrolling down
      if (currScrollPos > 48) {
        this.setState({
          scrolled: true,
          upScroll: false
        });
      }
    } else {
      // scrolling up
      if (currScrollPos < 48) {
        this.setState({
          scrolled: false,
          upScroll: false
        });
      } else {
        this.setState({ upScroll: true });
      }
    }
    this.setState({
      lastScrollPos: currScrollPos
    });
  }

  toggleMenu = () => {
    this.setState({ navOpen: !this.state.navOpen })
  }

  render() {
    const { data, location = {} } = this.props
    const { pathname = null } = location
    const { site, headerNavigation } = data
    const { title: siteName, wordpressUrl } = site.siteMetadata
    const {
      scrolled,
      upScroll,
      navOpen,
      isHome
    } = this.state

    return (
      <header className={`${scrolled ? 'scrolled ' : ''}${upScroll ? 'up-scroll ' : ''}${isHome ? 'is-home ' : ''}`}>
        <div className={`inner ${navOpen ? 'nav-open' : ''}`}>
          <div className="more-inner">
            <Logo
              siteName={siteName}
              url="/"
              // closeNavigation={event => this.closeNavigation(event)}
            />
            <Navigation
              items={headerNavigation.items}
              currentPath={pathname}
              wordpressUrl={wordpressUrl}
              navOpen={navOpen}
              toggleMenu={this.toggleMenu}
            />
            <MenuButton toggleMenu={this.toggleMenu} navOpen={navOpen} />
          </div>
        </div>
      </header>
    )
  }
}

export const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            wordpressUrl
          }
        }
        headerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "header-menu" }
        ) {
          items {
            wordpress_id
            order
            wordpress_parent
            title
            url
            attr
            target
            classes
            xfn
            description
            object_id
            object
            object_slug
            type
            type_label
            children: wordpress_children {
              wordpress_id
              order
              wordpress_parent
              title
              url
              attr
              target
              classes
              xfn
              description
              object_id
              object
              object_slug
              type
              type_label
            }
          }
        }
      }
    `}
    render={data => <HeaderTemplate data={data} {...props} />}
  />
)
