import React from 'react';
import Helmet from 'react-helmet';
import '../sass/global/styles.scss';
import { Header, Footer } from './Common';
import { SiteNotice } from '../components/SiteNotice'

const Layout = ({ location, children }) => (
  <>
  <SiteNotice/>
  <div id="layout">
    <Helmet title="Home | Lycopodium" />
    <span id="top"></span>
    <Header location={location} />
    <main>
      {children}
    </main>
    <Footer location={location} />
  </div>
  </>
)

export default Layout;
