import React, { Component } from 'react'
import GatsbyLink from '../GatsbyLink';
import { isMobileView } from '../../utils/helpers'

const NavigationLink = props => {
  const {
    item,
    // closeNavigation,
    currentPath,
    wordpressUrl,
  } = props
  const { children, classes, url, title } = item
  const isParentLink = Boolean(children && children.length > 0)
  const pageUrl = url
  
  return (
    <GatsbyLink to={pageUrl}>{title}</GatsbyLink>
  )
}

const SubNavigation = props => {
  const { items, currentPath, wordpressUrl } = props
  return (
    <ul className="sub">
      {items.map(child => (
        <li key={child.order} className={`child ${child.classes}`}>
          <NavigationLink
            item={child}
            wordpressUrl={wordpressUrl}
            currentPath={currentPath}
          />
        </li>
      ))}
    </ul>
  )
}

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileBreakpoint: 1199,
      isNavOpen: false,
    }
  }                  
  
  handleClick(e) {
    // we want this function to only appear when the window break occurs.
    if (isMobileView(this.state.mobileBreakpoint)) {
      if (e.target.getAttribute('href') === this.props.currentPath) {
        this.props.toggleMenu()
      }
    }
  }

  render() {
    const {
      items,
      currentPath,
      wordpressUrl,
      navOpen
    } = this.props
    return (
      <nav
        className={`${navOpen ? 'nav-open' : ''}`}
      >
        <ul>
          {items.map((navItem, index) => {
            const { order, children, classes, url, title } = navItem
            const isParent = children && children.length > 0
            return (
              <li
                key={order}
                onClick={(event) => this.handleClick(event)}  
              >
                <NavigationLink
                  wordpressUrl={wordpressUrl}
                  item={navItem}
                  currentPath={currentPath}
                  
                />
                {children && children.length > 0 ? (
                  <SubNavigation
                    wordpressUrl={wordpressUrl}
                    items={children}
                    currentPath={currentPath}
                  />
                ) : null}
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }
}
