import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Navigation from './Navigation'
import { Logo } from './Logo'
import './Footer.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { isMobileView } from '../../utils/helpers';
import { NewsletterSignup } from './NewsletterSignup'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import CookiePolicy from '../CookiePolicy'

class FooterTemplate extends Component {
  state = {

    onVisiblePercent: 20,
  }

  componentDidMount() {
    this.setOnVisiblePercentForMobile(400);
  }

  // Small function to check if the
  setOnVisiblePercentForMobile(mobileBreakPoint = 400) {
    if (isMobileView(mobileBreakPoint)) {
      this.setState({onVisiblePercent: 5})
    }
  }

  render() {
    const { data, location = {} } = this.props
    const { onVisiblePercent } = this.state;
    const { pathname = null } = location
    const { site, siteSettings, footerNavigation } = data
    const { title: siteName, wordpressUrl } = site.siteMetadata
    const { options } = siteSettings
    const { name, addressDisplay, phoneNumber, newsletterSignup, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText} = options
    return (
      <>
        {newsletterSignup && <NewsletterSignup url={newsletterSignup} />}
        <footer>
          <div className="footer-shadow"></div>
          <div className="wrapper">
            <div className="footer-helper">
              <OnVisible percent={onVisiblePercent ? onVisiblePercent : 25}>
                <div className="footer-content-wrapper">
                  <div className="logo-wrapper">
                    <Logo
                      siteName={siteName}
                      url="/#"
                      // closeNavigation={event => this.closeNavigation(event)}
                    />
                    {/* <div className="logo-right">
                      <img alt="Lycopodium - the future is yours" src="/logos/footer_logo_future.svg" />
                    </div> */}
                  </div>
                  <div className="flex-row top-part">
                    <div className="contact-details">
                      <span
                        className="name"
                        dangerouslySetInnerHTML={{__html: name}}
                      />
                      <span
                        className="addressDisplay"
                        dangerouslySetInnerHTML={{__html: addressDisplay}}
                      />
                      <span className="phoneNumber">
                        <span style={{display: 'inline'}}>Phone: </span><a style={{textDecoration: 'none', color: 'inherit'}} href={`tel:${phoneNumber}`} dangerouslySetInnerHTML={{__html: phoneNumber}} />
                      </span>
                    </div>
                    <div className="footer-navigation">
                      <Navigation
                        items={footerNavigation.items}
                        currentPath={pathname}
                        wordpressUrl={wordpressUrl}
                      />
                    </div>
                  </div>
                  {/* <div className="logo-alt__mobile">
                      <img alt="Lycopodium - the future is yours" src="/logos/footer_logo_future.svg" />
                  </div> */}
                  <div className="flex-row bottom-part">
                    <div className="copyright">
                      Copyright &copy;{' '}
                      <span
                        className="name"
                        dangerouslySetInnerHTML={{ __html: name }}
                      />{' '}
                      | <a href="/sitemap.xml">Sitemap</a> | <a href="/information/privacy-policy/">Terms of Use</a>
                    </div>
                  </div>
                </div>
              </OnVisible>
              {/* BAZINGA is a good reminder of what NOT to do */}
              <AnchorLink className="BAZINGA" href="#top"></AnchorLink>
            </div>
          </div>
        </footer>
        {privacyCookiePolicyNotice &&
          <CookiePolicy content={privacyCookiePolicyNoticeText}/>
        }
      </>
    )
  }
}

export const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            wordpressUrl
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            name
            addressDisplay
            phoneNumber
            newsletterSignup
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        metaData: wordpressSiteMetadata {
          url
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-menu" }
        ) {
          items {
            wordpress_id
            order
            wordpress_parent
            title
            url
            attr
            target
            classes
            xfn
            description
            object_id
            object
            object_slug
            type
            type_label
            children: wordpress_children {
              wordpress_id
              order
              wordpress_parent
              title
              url
              attr
              target
              classes
              xfn
              description
              object_id
              object
              object_slug
              type
              type_label
            }
          }
        }
      }
    `}
    render={data => <FooterTemplate data={data} {...props} />}
  />
)
